import { URL_PATHS } from '@constants';
import { ILangState } from '@contextTypes';
import { IPage, IPost, IPostPage } from '@standardTypes';
import { usePostsWithCategory } from '@utils';

import React, { useMemo } from 'react';

import { Breadcrumbs } from '@components/atoms';
import { LangCtx, useContextState } from '@components/contexted';
import { Layout } from '@components/layout';
import { Post } from '@components/organisms/standard';

interface IPageContext {
  pageContext: {
    page: IPostPage;
    nextPost: IPostPage;
    prevPost: IPostPage;
    posts: IPost[];
    pages: IPage[];
  };
}

const PostPage: React.FC<IPageContext> = ({ pageContext }): JSX.Element => {
  const { page, nextPost, prevPost, posts, pages } = pageContext;
  const { slug, title, allLanguages } = page;
  const { language } = useContextState<ILangState>(LangCtx, 'language');

  const newsletterContentPage = pages.filter(
    (el) => el.slug === 'newsletter-content'
  );
  const { ACF_NewsletterContent } = newsletterContentPage[0];

  const currentPostCategory = page.categories.nodes[0].slug;
  const similarPosts = posts.filter(
    (post) => post.language.code.toLowerCase() === language
  );
  const similarCatPosts = usePostsWithCategory(
    similarPosts,
    currentPostCategory
  ).slice(0, 2);

  const popularPostArticle: IPost[] = usePostsWithCategory(
    similarPosts,
    'articles'
  ).slice(0, 2);

  const popularPostCaseStudy: IPost[] = usePostsWithCategory(
    similarPosts,
    'case-study'
  ).slice(0, 1);

  const popularPostTestersEye: IPost[] = usePostsWithCategory(
    similarPosts,
    'testers-eye'
  ).slice(0, 1);

  const popularPostVideo: IPost[] = usePostsWithCategory(
    similarPosts,
    'video-and-podcast'
  ).slice(0, 1);

  const categoryPosts = [
    popularPostArticle,
    popularPostCaseStudy,
    popularPostTestersEye,
    popularPostVideo
  ];

  const translationUrls = useMemo(
    () =>
      allLanguages.reduce((acc: Record<string, string>, el) => {
        return { ...acc, [el.code]: `/${el.code}/blog/${el.slug}` };
      }, {}),
    []
  );

  console.log('p', page);

  return (
    <Layout {...page} translationUrls={translationUrls}>
      <Post
        page={page}
        nextPost={nextPost}
        prevPost={prevPost}
        similarCatPosts={similarCatPosts}
        categoryPosts={categoryPosts}
        newsletterContent={ACF_NewsletterContent}
      />
    </Layout>
  );
};

export default PostPage;
